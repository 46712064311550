// src/components/Navbar.js
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';

function Navbar() {
  return (
    <AppBar position="static" color={"primary"}>
      <Toolbar>
        <Typography variant="h4" component="div" sx={{fontFamily:'Dancing Script, cursive',  flexGrow: 2 }}>
          CRM Mobil 1 express
        </Typography>
        <Box>
          <Button color="inherit" sx={{fontFamily:'Montserrat, sans-serif'}}  component={Link} to="/">
            Главная
          </Button>
          <Button color="inherit"  sx={{fontFamily:'Montserrat, sans-serif'}} component={Link} to="/clients">
            Клиенты
          </Button>
          <Button color="inherit"  sx={{fontFamily:'Montserrat, sans-serif'}} component={Link} to="/settings">
            Настройки
          </Button>
          <Button color="inherit"  sx={{fontFamily:'Montserrat, sans-serif'}} component={Link} to="/logout">
            Выход
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
